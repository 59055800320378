<template>
  <v-form class="mt-2">
    <v-row v-if="data && data.VolumeValues && data.VolumeValues.length">
      <v-col>
        <tfoms-bar
          title="Выполнение плана"
          :data="getValues(data.VolumeValues)"
          icon="mdi-currency-rub"
          :labels="labels"
          :sub-title="subTitlePeriod"
          :values="[
            {
              label: this.provideUnits.unit.text,
            },
          ]"
        ></tfoms-bar
      ></v-col>
      <v-col>
        <tfoms-lost-income
          :plan="data.VolumeTotalPlanValue"
          :fact="data.VolumeTotalValue"
          :percent="data.VolumePercent"
        ></tfoms-lost-income>
      </v-col>
    </v-row>
    <tfoms-condition-table
      v-if="data && data.Children"
      :data="
        data.Children.map((e) => ({
          plan: e.VolumePlanValue,
          fact: e.VolumeValue,
          percent: e.VolumeValuePercent,
          Name: e.Name,
          id: e.Id,
          Codes: e.Codes,
        }))
      "
      :labels="labels"
      :subTitle="subTitlePeriod"
      class="mt-5"
      :values="[
        {
          label: this.provideUnits.unit.text,
        },
      ]"
    >
    </tfoms-condition-table>
    <v-row class="mt-5">
      <v-col
        ><tfoms-partition-table
          v-if="data && data.Divisions"
          v-model="selectEmployee"
          :data="data.Divisions"
          :labels="labels"
          :sub-title="subTitlePeriod"
          :values="[
            {
              label: this.provideUnits.unit.text,
            },
          ]"
        >
        </tfoms-partition-table
      ></v-col>
      <v-col>
        <tfoms-employee-table
          v-if="data && data.Divisions"
          :selectPartiton="selectEmployee"
          :data="data.Divisions"
          :labels="labels"
          :items="data.Employees"
          :sub-title="subTitlePeriod"
          :values="[
            {
              label: this.provideUnits.unit.text,
            },
          ]"
        >
        </tfoms-employee-table
      ></v-col>
    </v-row>
  </v-form>
</template>
<script>
import tfomsTab from "../../mixins/tfomsTab";

export default {
  name: "view-AnalysisTform-more1",

  components: {
    // tfomsProgress,
    tfomsLostIncome: () => import("../../components/tfomsLostIncome.vue"),
    tfomsBar: () => import("../../components/tfomsBar.vue"),
    tfomsConditionTable: () => import("../../components/tfomsConditionTable"),
    tfomsPartitionTable: () => import("../../components/tfomsPartitionTable"),
    tfomsEmployeeTable: () => import("../../components/tfomsEmployeeTable.vue"),
  },
  mixins: [tfomsTab],
  props: {
    data: { type: Object, default: null },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      selectEmployee: null,
    };
  },
  watch: {
    data(val) {
      this.selectEmployee = null;
    },
  },
  methods: {
    init() {},
  },
};
</script>
